import React, { useRef, useState } from "react";
import clsx from "clsx";
import { Entity } from "@xeokit/xeokit-sdk";
import { observer } from "mobx-react-lite";
import { bimViewerStore } from "store/BimViewerStore";
import { useParams } from "react-router";
import Button from "components/Button";
import { IconBuildingSkyscraper, IconCopyCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import DetailsView from "features/MappingTools/ObjectDetailsSlideOver/DetailsView";
import { buildingStore } from "store/Building/BuildingStore";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { SingleItem } from "api-client";

export default observer(function DME3DViewer({ height }: { height?: string }) {
  const viewerRef = useRef<HTMLCanvasElement>(null);
  const [openViewerDetailView, setOpenViewerDetailView] =
    useState<boolean>(false);
  const { ifc_id } = useParams();
  const fileSrc = buildingStore.getXKTFileUrl(ifc_id);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (viewerRef.current && fileSrc) {
      const viewer = bimViewerStore.createViewer("bimViewerCanvas");
      bimViewerStore.setViewer(viewer);
      bimViewerStore.loadFile(fileSrc);
      bimViewerStore.handleObjectsOnclick(goToObjectDetail);

      return () => {
        viewer.destroy();
        bimViewerStore.reset();
      };
    }
  }, [buildingStore.materialResources]);

  async function goToObjectDetail(object: Entity) {
    if (!ifc_id) return;
    dynamicMEStore.setOpenedObjectDetails(false);
    await bimViewerStore.fetchDetailsInfo(ifc_id, String(object.id));
    dynamicMEStore.setCurrentRow(undefined);
    bimViewerStore.setCurrentObject(object);
    setOpenViewerDetailView(true);
  }

  const selectedIfcIDs = dynamicMEStore.selectedRows.map(
    (item) => item?.ifc_global_id as string
  );

  function closeViewerDetailView() {
    setOpenViewerDetailView(false);
    const noHighlightAndSelection =
      selectedIfcIDs.length === 0 && dynamicMEStore.currentRow === undefined;

    bimViewerStore.setObjectSelectStatus(false, noHighlightAndSelection);
    if (!dynamicMEStore.currentRow) {
      selectedIfcIDs.length
        ? bimViewerStore.zoomToSelected(selectedIfcIDs)
        : bimViewerStore.zoomToBuilding();
    }
  }

  React.useEffect(() => {
    highlightSelectedIn3DViewer();
  }, [
    dynamicMEStore.selectedRows.length,
    dynamicMEStore.allObjectsIfcIDs,
    bimViewerStore.isLoaded,
    dynamicMEStore.selectedFilterByItems,
    dynamicMEStore.selectedGroupsGlobalIDs,
  ]);

  React.useEffect(() => {
    if (dynamicMEStore.currentRow) {
      const currentID = (dynamicMEStore.currentRow?.original as SingleItem)
        ?.ifc_global_id as string;
      bimViewerStore.setSelected([currentID], true);
      closeViewerDetailView();
    } else {
      bimViewerStore.removeSelected(selectedIfcIDs);
    }
  }, [dynamicMEStore.currentRow, bimViewerStore.isLoaded]);

  function highlightSelectedIn3DViewer() {
    const selectedIDs = dynamicMEStore.hasGrouping()
      ? dynamicMEStore.selectedGroupsGlobalIDs
      : selectedIfcIDs;

    bimViewerStore.setHighlighted(
      dynamicMEStore.allObjectsIfcIDs,
      selectedIDs,
      dynamicMEStore.selectAllChecked
    );
  }

  function viewButtons() {
    return (
      <div
        className={clsx(
          "absolute h-full transition-all duration-500 top-17 w-fit"
        )}
      >
        <div className="flex h-full">
          <div className="bg-white h-fit m-5 rounded-md p-3 shadow-sm flex">
            {bimViewerStore.viewer && (
              <>
                <Button
                  type="gray"
                  onClick={() => bimViewerStore.zoomToBuilding()}
                  width="fit-content"
                  trailingIcon={<IconBuildingSkyscraper />}
                >
                  {t("mappingEditor.centerBuilding")}
                </Button>
                {selectedIfcIDs.length ? (
                  <Button
                    type="gray"
                    onClick={() =>
                      bimViewerStore.zoomToSelected(selectedIfcIDs)
                    }
                    width="fit-content"
                    trailingIcon={<IconCopyCheck />}
                    className="ml-3"
                  >
                    {t("mappingEditor.centerSelection")}
                  </Button>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  const refreshAfterMatching = async () => {
    if (!ifc_id) return;
    await bimViewerStore.fetchDetailsInfo(
      ifc_id,
      String(bimViewerStore.currentObject?.id)
    );
  };

  return (
    <div className={clsx("flex relative", height)}>
      <canvas
        ref={viewerRef}
        id="bimViewerCanvas"
        className={clsx("bg-indigo-50 w-full", height)}
      ></canvas>
      {viewButtons()}
      <DetailsView
        open={openViewerDetailView}
        close={closeViewerDetailView}
        objectDetailsList={
          bimViewerStore.objectDetail ? [...bimViewerStore.objectDetail] : []
        }
        refreshAfterMatching={refreshAfterMatching}
      />
    </div>
  );
});
