import React from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import Dashboard from "../pages";
import LogIn from "../pages/Onboarding/Login";
import Onboarding from "pages/Onboarding";
import CreateOrganization from "features/Organisation/CreateOrganisation";
import Building from "../pages/Building";
import Audits from "../pages/Audit/Audits";
import Audit from "../pages/Audit/Audit";
import Blueprint from "../pages/Audit/Blueprint";
import BuildingDetail from "features/Building/BuildingPassport";
import BuildingMaterialResources from "features/Building/BuildingMaterialResources";
import BlueprintAdd from "pages/Audit/BlueprintAdd";
import InstanceAdd from "pages/Audit/InstanceAdd";
import { useAuth0 } from "@auth0/auth0-react";
import BlueprintDetail from "pages/Audit/BlueprintDetail";
import Instance from "pages/Audit/Instance";
import Verify from "pages/Onboarding/Verify";
import Mapping from "pages/Building/Mapping";
import BuildingInventory from "features/Building/BuildingInventory";
import BimViewerPage from "pages/Building/BimViewerPage";
import Exports from "pages/Building/Exports";
import ProductEditorPage from "pages/Building/ProductEditorPage";
import Revit from "pages/Revit";
import RevitConnectedView from "features/Revit/RevitConnectedView";
import AuditsOnline from "pages/AuditOnline/AuditsOnline";
import BlueprintList from "pages/AuditOnline/BlueprintListOnline";
import BlueprintDetailOnline from "pages/AuditOnline/BlueprintDetailOnline";
import InstanceOnline from "pages/AuditOnline/InstanceOnline";
import InstanceAddOnline from "pages/AuditOnline/InstanceAddOnline";
import BlueprintAddOnline from "pages/AuditOnline/BlueprintAddOnline";
import BlueprintOnline from "pages/AuditOnline/BlueprintOnline";
import TermsAndConditions from "pages/TermsAndConditions";
import Variants from "features/Building/Variants";
import { userStore } from "store/UserStore";
import { observer } from "mobx-react-lite";
import useCAOnlyFeature from "hooks/useCAOnlyFeature";
import ComponentEditorPage from "pages/Building/ComponentEditorPage";
import AuditMaterialSales from "pages/Retool/SalesApproval";
import PortfolioDashboard from "pages/Retool/PortfolioDashboard";
import UrbanMiningHub from "pages/Retool/UrbanMiningHub";
import { PORTFOLIO_URL, URBAN_MINING_HUB_URL } from "utils";
import MappingEditorPage from "pages/Building/MappingEditorPage";

const AppRoutes = () => {
  const { isAuthenticated } = useAuth0();
  const { onboarded, verified } = userStore;
  const { caOnlyFeature } = useCAOnlyFeature();

  function onboardingRoutes() {
    return (
      <>
        <Route
          path="/"
          element={
            <Navigate
              to={
                !verified ? "/verify" : onboarded ? "/dashboard" : "/onboarding"
              }
              replace
            />
          }
        />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/verify" element={<Verify />} />
        <Route
          path="/onboarding/create-organisation"
          element={<CreateOrganization />}
        />
      </>
    );
  }

  function buildingRoutes() {
    return (
      <Route path="/buildings/:id/" element={<Building />}>
        {caOnlyFeature ? (
          <>
            <Route path="" element={<Navigate replace to="audits" />} />
            {auditsRoutes()}
            {auditsOnlineRoutes()}
          </>
        ) : (
          <>
            <Route path="" element={<Navigate replace to="passport" />} />
            <Route
              path="audit-material-sales"
              element={<AuditMaterialSales />}
            />
            <Route path="passport" element={<BuildingDetail />} />
            <Route path="exports" element={<Exports />} />
            <Route
              path="material-resources"
              element={<BuildingMaterialResources />}
            />
            <Route path="variants" element={<Variants />} />
            <Route path="mapping/:ifc_id" element={<Mapping />} />
            <Route
              path="mapping-editor/:ifc_id"
              element={<MappingEditorPage />}
            />
            <Route path="viewer/:ifc_id" element={<BimViewerPage />} />
            <Route path="inventory" element={<BuildingInventory />} />
            <Route
              path="mapping/:ifc_id/product-editor"
              element={<ProductEditorPage />}
            />
            <Route
              path="mapping-editor/:ifc_id/product-editor"
              element={<ProductEditorPage />}
            />
            <Route
              path="mapping/:ifc_id/component-editor"
              element={<ComponentEditorPage />}
            />
            <Route
              path="mapping-editor/:ifc_id/component-editor"
              element={<ComponentEditorPage />}
            />
            {auditsRoutes()}
            {auditsOnlineRoutes()}
          </>
        )}
      </Route>
    );
  }

  function auditsRoutes() {
    return (
      <Route path="audits" element={<Audits />}>
        <Route path=":audit_id" element={<Audit />}>
          <Route path="blueprints">
            <Route path="add" element={<BlueprintAdd />} />
            <Route path=":blueprint_id" element={<Blueprint />}>
              <Route path="add" element={<InstanceAdd />} />
              <Route path="instances" element={<BlueprintDetail />} />
              <Route path="instances/:instance_id" element={<Instance />} />
            </Route>
          </Route>
        </Route>
      </Route>
    );
  }

  function auditsOnlineRoutes() {
    return (
      <Route path="audits-online" element={<AuditsOnline />}>
        <Route path=":audit_id" element={<BlueprintList />}>
          <Route path="blueprints">
            <Route path="add" element={<BlueprintAddOnline />} />
            <Route path=":blueprint_id" element={<BlueprintOnline />}>
              <Route path="add" element={<InstanceAddOnline />} />
              <Route path="instances" element={<BlueprintDetailOnline />} />
              <Route
                path="instances/:instance_id"
                element={<InstanceOnline />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    );
  }

  function revitRoutes() {
    return (
      <Route path="/revit-setup/:lang">
        <Route path="" element={<RevitView />} />
        <Route path="connected" element={<RevitConnected />} />
      </Route>
    );
  }

  function RevitView() {
    const { lang } = useParams();
    const language = lang || "en";

    return <Revit lang={language} />;
  }

  function RevitConnected() {
    const { lang } = useParams();
    const language = lang || "en";

    return <RevitConnectedView lang={language} />;
  }

  return (
    <Routes>
      {!isAuthenticated ? (
        <Route path="*" element={<LogIn />} />
      ) : (
        <>
          {onboardingRoutes()}
          <Route path="/dashboard" element={<Dashboard />} />
          {buildingRoutes()}
          <Route
            path={`:organisation_id/${PORTFOLIO_URL}`}
            element={<PortfolioDashboard />}
          />
          <Route
            path={`:organisation_id/${URBAN_MINING_HUB_URL}`}
            element={<UrbanMiningHub />}
          />
          {revitRoutes()}
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
        </>
      )}
    </Routes>
  );
};

export default observer(AppRoutes);
