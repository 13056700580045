import Button from "components/Button";
import CircularProgressBar from "components/CircularProgressBar";
import LoadingIcon from "components/LoadingIcon";
import { useComplianceInfo } from "hooks/useComplianceInfo";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import { buildingStore } from "store/Building/BuildingStore";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { getModuleStringTotal } from "utils";

export default observer(function ComplianceSettingsTile() {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { currentBuilding, materialResources } = buildingStore;
  const { getCurrentConformity } = useComplianceInfo();

  const percentage = Math.round(
    100 *
      (Number(buildingSettingsStore.compliantObjects.compliant || 0) /
        Number(buildingSettingsStore.compliantObjects.total || 1))
  );

  const allFilesCount = materialResources.items.length;
  const activeFileIDsList = materialResources.items
    .filter((item) => item.reporting_status === "ACTIVE")
    .map((item) => item.id);

  const emptyActiveFiles = activeFileIDsList.length == 0;

  useEffect(() => {
    (async () => await getCompliantDatasetInfo())();
  }, [materialResources.loading, emptyActiveFiles, allFilesCount]);

  async function getCompliantDatasetInfo() {
    if (emptyActiveFiles === true || materialResources.loading) return;
    buildingSettingsStore.setCompliantObjects({});
    await buildingStore
      .fetchCheckDatasetCompliance(
        currentBuilding?.id,
        currentBuilding?.certification_id,
        currentBuilding?.conformity_id
      )
      .then((response) => {
        buildingSettingsStore.setCompliantObjects({
          compliant: response?.compliant_objects,
          total: response?.total_objects,
        });
      });
  }

  const getCurrentCertification = () => {
    const current = ifcMappingStore.certificationOptions.find(
      (item) => item.id === currentBuilding?.certification_id
    );
    return isEng
      ? current?.name_en ?? current?.name
      : current?.name_de ?? current?.name;
  };

  function linkedObjectsTile() {
    const currentCertificate = getCurrentCertification();

    return (
      <div className="flex items-center w-full">
        <div className="flex items-center mr-4 w-12">
          {buildingSettingsStore.compliantObjects.total ? (
            <CircularProgressBar
              size={32}
              strokeWidth={7}
              textStyle="!text-sm font-semibold text-white !mt-0 mr-0 ml-1"
              activeStroke="#EF4444"
              percent={percentage}
              classsName="!w-16 !h-16 flex items-center"
            />
          ) : (
            <div className="p-1 h-16">
              <LoadingIcon className="!h-[55px] !w-[55px] !text-gray-200" />
            </div>
          )}
        </div>
        {buildingSettingsStore.compliantObjects.total ? (
          <div className="text-white pl-5">
            <div className="text-sm pb-2">
              {t("buildingPassport.linkedObjectsInfo", {
                linked_objects:
                  buildingSettingsStore.compliantObjects.compliant ?? "0",
                total_objects:
                  buildingSettingsStore.compliantObjects.total ?? "-",
              })}
            </div>
            <div className="text-xs text-indigo-100">
              {["None", "Keine"].includes(String(currentCertificate))
                ? t("buildingPassport.complianceInfoNone", {
                    conformity: getCurrentConformity() ?? "-",
                    moduleString: t(
                      `commons.${getModuleStringTotal(currentCertificate)}`
                    ),
                  })
                : t("buildingPassport.complianceInfo", {
                    certification: currentCertificate ?? "-",
                    conformity: getCurrentConformity() ?? "-",
                    moduleString: t(
                      `commons.${getModuleStringTotal(currentCertificate)}`
                    ),
                  })}
            </div>
          </div>
        ) : (
          skeleton()
        )}
      </div>
    );
  }

  if (buildingStore.checkIfNoBuildingDashboard()) return <></>;
  return (
    <div className="bg-indigo-500 px-6 py-4 rounded-md mt-3">
      <div className="flex justify-between items-center">
        {linkedObjectsTile()}
        <Button
          type="secondary"
          width="fit-content"
          className="min-w-fit bg-white"
          onClick={() => buildingSettingsStore.setOpenEditBuilding(true)}
        >
          {t("buildingPassport.editBuildingSettings")}
        </Button>
      </div>
    </div>
  );
});

function skeleton() {
  return (
    <div className="animate-pulse pl-5 w-[600px] max-w-full">
      <div className="h-6 w-full bg-indigo-300 rounded-lg dark:bg-indigo-700 mb-2.5"></div>
      <div className="h-4 w-[300px] max-w-full bg-indigo-300 rounded-lg dark:bg-indigo-700"></div>
    </div>
  );
}
