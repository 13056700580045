import React, { useEffect, useState } from "react";
import { PlotParams } from "react-plotly.js";
// @ts-ignore
import Plotly from "plotly.js-concore";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

export interface BasePlotProps {
  data: Plotly.Data[];
  layout?: Partial<Plotly.Layout>;
  frames?: Plotly.Frame[] | undefined;
  config?: Partial<Plotly.Config> | undefined;
}

export default function BasePlot(props: BasePlotProps) {
  const [state, setState] = useState<PlotParams>({
    data: props.data,
    layout: props.layout || {},
    frames: props.frames || [],
    config: props.config || {},
  });

  useEffect(() => {
    if (props.data === state.data) return;
    setState((previous) => {
      return { ...previous, data: props.data };
    });
  }, [props.data]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Plot
        data={state.data}
        layout={state.layout}
        frames={state.frames}
        config={state.config}
        onInitialized={(figure) => setState(figure as PlotParams)}
        onUpdate={(figure) => setState(figure as PlotParams)}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}
