import { buildingDashboardStore } from "store/Building/BuildingDashboardStore";
import BuildingEnvironmentalPanel from "./Panels/BuildingEnvironmentalPanel";
import BuildingCPXPanel from "./Panels/BuildingCPXPanel";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import BuildingEnergyPanel from "./Panels/BuildingEnergyPanel";
import BuildingEmissionsPanel from "./Panels/BuildingEmissionsPanel";
import BuildingMassPanel from "./Panels/BuildingMassPanel";
import BuildingMaterialValuePanel from "./Panels/BuildingMaterialValuePanel";
import { buildingStore } from "store/Building/BuildingStore";
import EmptyState from "components/EmptyState";
import { useTranslation } from "react-i18next";

export default observer(function BuildingPlots() {
  const store = buildingDashboardStore;
  const { t } = useTranslation();

  useEffect(() => {
    store.initialFetch();
  }, [store.currentBuildingID]);

  if (buildingStore.checkIfNoBuildingDashboard()) {
    return (
      <EmptyState
        type="chart"
        containerClassName="mt-4"
        loadingMessage={t("dashboards.loadingDashboardMessage")}
      />
    );
  }

  if (buildingStore.calculationsStatus.inProgress) {
    return (
      <EmptyState
        type="chart"
        loadingMessage={t("dashboards.recalculationLoadingMessage")}
        loading
        containerClassName="mt-4"
      />
    );
  }

  return (
    <div className="flex flex-col mt-5">
      {store.cpxInfo && <BuildingCPXPanel info={store.cpxInfo} />}
      {store.emissionsInfo && (
        <BuildingEmissionsPanel
          info={store.emissionsInfo}
          plotXRange={store.emissionsAxisRange}
        />
      )}
      {store.environmentalInfo && (
        <BuildingEnvironmentalPanel info={store.environmentalInfo} />
      )}
      {store.operationalEnergyInfo && (
        <BuildingEnergyPanel info={store.operationalEnergyInfo} />
      )}
      {store.massInfo && <BuildingMassPanel info={store.massInfo} />}
      {store.materialValueInfo && (
        <BuildingMaterialValuePanel info={store.materialValueInfo} />
      )}
    </div>
  );
});
