import { DashboardBuildingOperationalEnergy } from "api-client";
import BasePlot, { BasePlotProps } from "features/Dashboards/Base/BasePlot";
import DashboardSimpleNumber from "features/Dashboards/Base/DashboardSimpleNumber";

export interface BuildingEnergyPanelProps {
  info: DashboardBuildingOperationalEnergy;
}

const colors = [
  "rgba(99, 102, 241, 1)", // #6366f1
  "rgba(165, 180, 252, 1)", // #a5b4fc
  "rgba(6, 182, 212, 1)", // #06b6d4
  "rgba(103, 232, 249, 1)", // #67e8f9
];

const layout: Partial<Plotly.Layout> | object = {
  autosize: true,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  barmode: "relative",
  legend: {
    orientation: "h",
    y: -0.2,
  },
  margin: {
    l: 0,
    r: 0,
    b: 10,
    t: 0,
  },
  title: null,
  yaxis: {
    visible: false,
  },
  xaxis: {
    tickmode: "auto",
  },
};

const baseConfig: BasePlotProps = {
  data: [],
  layout,
  config: {
    displaylogo: false,
    showTips: true,
    responsive: true,
    displayModeBar: false,
  },
};

function EnergyPlot(props: {
  title: string;
  total: number | null | undefined;
  unit: string;
  plotConfig: BasePlotProps;
}) {
  const config = {
    ...baseConfig,
    ...props.plotConfig,
  };

  return (
    <div className="flex flex-col">
      <div className="text-center">
        <h3 className="text-md">{props.title}</h3>
      </div>
      <div className="flex mb-3 h-28">
        <BasePlot {...config} />
      </div>
    </div>
  );
}

function GreyEmissionsPlot(props: {
  info: DashboardBuildingOperationalEnergy;
}) {
  const plotConfig: BasePlotProps = {
    data: [
      {
        y: ["gwp"],
        x: [props.info.gwp_b6_1 ?? null],
        name: "B6.1",
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[0] },
      },
      {
        y: ["gwp"],
        x: [props.info.gwp_b6_2 ?? null],
        name: "B6.2",
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[1] },
      },
      {
        y: ["gwp"],
        x: [props.info.gwp_b6_3 ?? null],
        name: "B6.3",
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[2] },
      },
      {
        y: ["gwp"],
        x: [props.info.gwp_self_generated ?? null],
        name: "EPE",
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[3] },
      },
    ],
  };

  return (
    <div className="h-full">
      <EnergyPlot
        title="GWP Module"
        total={props.info.gwp_total}
        unit="kg CO2e / m²a"
        plotConfig={plotConfig}
      />
    </div>
  );
}

export function OperationalEnergyPlot(props: {
  info: DashboardBuildingOperationalEnergy;
}) {
  const plotConfig: BasePlotProps = {
    data: [
      {
        y: ["energy"],
        x: [props.info.penrt_b6_1 ?? null],
        name: "B6.1",
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[0] },
      },
      {
        y: ["energy"],
        x: [props.info.penrt_b6_2 ?? null],
        name: "B6.2",
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[1] },
      },
      {
        y: ["energy"],
        x: [props.info.penrt_b6_3 ?? null],
        name: "B6.3",
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[2] },
      },
      {
        y: ["energy"],
        x: [props.info.penrt_self_generated ?? null],
        name: "EPE",
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[3] },
      },
    ],
  };

  return (
    <div className="h-full">
      <EnergyPlot
        title="PENRT Module"
        total={props.info.penrt_total}
        unit="kWh / m²a"
        plotConfig={plotConfig}
      />
    </div>
  );
}

export default function BuildingEnergyPanel(props: BuildingEnergyPanelProps) {
  return (
    <div className="border mb-6 border-gray-300 bg-white rounded-lg overflow-hidden p-6">
      <div className="flex-col">
        <div className="mb-5">
          <h3 className="text-lg font-bold">Emmisionen im Betrieb (B6)</h3>
        </div>
        <div className="grid grid-cols-6 gap-4 w-full block ">
          <div className="">
            <div className="flex flex-col gap-4">
              <DashboardSimpleNumber
                className="justify-center"
                title="GWP Total"
                subtitle="kg CO2e / m²a"
                value={props.info.gwp_total}
              />

              <DashboardSimpleNumber
                className="justify-center"
                title="GWP D2"
                subtitle="kg CO2e / m²a"
                value={props.info.gwp_d2}
              />
            </div>
          </div>
          <div className="col-span-2  h-full">
            <GreyEmissionsPlot info={props.info} />
          </div>
          <div className="">
            <div className="flex flex-col gap-4">
              <DashboardSimpleNumber
                className="justify-center"
                title="PENRT Total"
                subtitle="kWh / m²a"
                value={props.info.penrt_total}
              />

              <DashboardSimpleNumber
                className="justify-center"
                title=""
                subtitle=""
                value={""}
              />
            </div>
          </div>
          <div className="col-span-2 h-full">
            <OperationalEnergyPlot info={props.info} />
          </div>
        </div>
      </div>
    </div>
  );
}
