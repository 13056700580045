import React from "react";
import Button from "components/Button";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Badge from "components/Badge";
import { observer } from "mobx-react-lite";
import useToast from "hooks/useToast";
import { AxiosError } from "axios";
import { ResultOUT, SingleItem } from "api-client";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import { IconStack2 } from "@tabler/icons-react";
import { useMappingTools } from "../useMappingTools";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import Tooltip from "components/Tooltip";
import { searchStore } from "store/IfcMapping/SearchStore";
import useFeatureFlag from "hooks/useFeatureFlag";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";

export default observer(function ComponentEditorHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { ifc_id } = useParams();
  const { mappingMatch, onCloseEditor } = useMappingTools();
  const toast = useToast();
  const { isFeatureVisible } = useFeatureFlag();
  const circularityStepExists = isFeatureVisible(
    "component_editor_circularity_step"
  );
  const isDME = location.pathname.includes("mapping-editor");

  async function updateComponent(doMatch: boolean | undefined) {
    const response = await componentsStore.updateComponent();
    if (doMatch) {
      isDME
        ? await dynamicMEStore.runSelectedObjectOperations(
            ifc_id,
            "manual_match",
            response.data.component.id,
            dynamicMEStore.currentRow?.original as SingleItem
          )
        : await mappingMatch(response.data.component.id);
    }
    if (response.data.is_recalculation_triggered) {
      toast(t("components.successMsgOnEditWithRecalculation"), "success");
    } else {
      toast(t("components.successMsgOnEdit"), "success");
    }
  }

  async function createComponent(doMatch: boolean | undefined) {
    const createdItem = await componentsStore.registerComponent();
    if (doMatch && createdItem) {
      isDME
        ? await dynamicMEStore.runSelectedObjectOperations(
            ifc_id,
            "manual_match",
            createdItem.id,
            dynamicMEStore.currentRow?.original as SingleItem
          )
        : await mappingMatch(createdItem.id);
    }
    toast(t("components.successMsgOnSave"), "success");
  }

  const saveAndClose = async (doMatch?: boolean) => {
    try {
      if (location.state?.mode === "edit") {
        await updateComponent(doMatch);
      } else {
        await createComponent(doMatch);
      }
      searchStore.setComponentDetails(undefined);
      componentsStore.resetData();
      onCloseEditor(3, doMatch);
    } catch (error) {
      toast(
        `${t("components.warningMsgOnSave")} ${
          (
            ((error as AxiosError)?.response?.data as ResultOUT)
              ?.message as ResultOUT
          )?.message ?? error
        }`,
        "warning"
      );
    }
  };

  const productName = componentsStore.data.name ?? "";

  return (
    <div className="sm:flex w-full px-4 py-2 bg-white items-center justify-between border-b border-gray-300 bg-gradient-to-r  from-cyan-50">
      <div id="editor-title" className="sm:flex items-center mb-3 sm:mb-0">
        <div className="flex justify-between">
          <IconStack2 className="bg-cyan-300 w-8 h-8 rounded-full p-1.5 mr-3" />
          <div className="text-xl font-semibold text-black mr-6 mb-2 sm:mb-0">
            {t("components.title")}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        {productName ? (
          <Tooltip
            className="text-base font-semibold leading-7 max-w-[500px]"
            truncate
          >
            {productName}
          </Tooltip>
        ) : null}
        <Badge type="info" className="ml-2 mb-2 sm:mb-0">
          {location.state?.mode
            ? t(`mapping.${location.state?.mode}`)
            : t("mapping.createNew")}
        </Badge>
      </div>
      <div className="flex">
        <Button
          width="fit-content"
          className="block mr-3"
          type="gray"
          onClick={() => navigate(-1)}
          disable={
            mappingEditorStore.mappingMatchLoading ||
            dynamicMEStore.showMappingLoading
          }
        >
          {t("commons.cancel")}
        </Button>
        <Button
          width="fit-content"
          className="block mr-3"
          type="secondary"
          onClick={() => saveAndClose()}
          disable={
            !componentsStore.checkSaveValidation(circularityStepExists) ||
            mappingEditorStore.mappingMatchLoading ||
            dynamicMEStore.showMappingLoading
          }
        >
          {t("commons.save")}
        </Button>
        <Button
          width="fit-content"
          className=" block"
          type="primary"
          loading={
            mappingEditorStore.mappingMatchLoading ||
            dynamicMEStore.showMappingLoading
          }
          onClick={() => saveAndClose(true)}
          disable={
            !componentsStore.checkSaveValidation(circularityStepExists) ||
            mappingEditorStore.mappingMatchLoading ||
            dynamicMEStore.showMappingLoading
          }
        >
          {t("components.saveAndMapp")}
        </Button>
      </div>
    </div>
  );
});
