import { DashboardBuildingEnvironmental, PlotlyTrace } from "api-client";
import SimpleSelect from "components/SimpleSelect";
import BasePlot, { BasePlotProps } from "features/Dashboards/Base/BasePlot";
import DashboardSimpleNumber from "features/Dashboards/Base/DashboardSimpleNumber";
import { observer } from "mobx-react";
import { Datum } from "plotly.js";
import {
  buildingDashboardStore,
  categorySystemOptions,
} from "store/Building/BuildingDashboardStore";

export interface BuildingEnvironmentalPanelProps {
  info: DashboardBuildingEnvironmental;
}

const colors = [
  "rgba(99, 102, 241, 1)", // #6366f1
  "rgba(165, 180, 252, 1)", // #a5b4fc
  "rgba(6, 182, 212, 1)", // #06b6d4
  "rgba(103, 232, 249, 1)", // #67e8f9
];

const layout: Partial<Plotly.Layout> | object = {
  autosize: true,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  barmode: "relative",
  legend: {
    orientation: "h",
  },
  title: null,
  yaxis: {
    autorange: true,
    automargin: true,
    visible: true,
    title: {
      text: "Materialien",
      standoff: 50,
    },
  },
  xaxis: {
    autorange: true,
    tickmode: "auto",
    title: "GWP",
  },
  hovermode: true,
};

const baseConfig: BasePlotProps = {
  data: [],
  layout,
  config: {
    displaylogo: false,
    showTips: true,
    responsive: true,
    displayModeBar: false,
  },
};

function BarPlot(props: { traces: Array<PlotlyTrace>; xUnit: string }) {
  const data: Partial<Plotly.Data>[] = props.traces.map((point, index) => {
    return {
      // tranposed axis
      x: point["y"] as Datum[],
      y: point["x"] as Datum[],
      name: point.name,
      orientation: "h",
      type: "bar",
      textposition: "auto",
      width: 0.5,
      marker: { color: colors[index] },
    };
  });

  const config: BasePlotProps = {
    ...baseConfig,
    data,
  };

  if (config.layout && config.layout.xaxis) {
    config.layout.xaxis.ticksuffix = ` ${props.xUnit}`;
  }

  return (
    <div className="flex flex-col">
      <BasePlot {...config} />
    </div>
  );
}

export default observer(function BuildingEnvironmentalPanel(
  props: BuildingEnvironmentalPanelProps
) {
  const store = buildingDashboardStore;

  function handleSystemSelection(value: string | undefined) {
    if (!value) return;
    store.setEnvironmentalCategorySystemSelected(value);
  }

  function handleUnitSelection(value: string | undefined) {
    if (!value) return;
    store.setEnvironmentalUnitSelected(value);
  }

  return (
    <div className="border mb-6 border-gray-300 bg-white rounded-lg overflow-hidden p-6">
      <div className="flex-col">
        <div className="mb-5">
          <h3 className="text-lg font-bold">Graue Emissionen (GWP)</h3>
        </div>
        <div className="flex justify-between mb-2">
          <div className="flex gap-5">
            <div>
              <SimpleSelect
                labelName="Kategoriensystem"
                options={categorySystemOptions}
                name="category_system"
                onChange={handleSystemSelection}
                current={store.environmentalCategorySystemSelected}
              />
            </div>
            <div>
              <SimpleSelect
                labelName="Einheit"
                options={store.environmentalUnitOptions}
                name="units"
                onChange={handleUnitSelection}
                current={store.environmentalUnitSelected}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-16 mr-10">
            <DashboardSimpleNumber
              className="justify-center w-36"
              title="Total"
              subtitle="kg CO2e / m²a"
              value={props.info.gwp_per_m2_and_year}
            />

            <DashboardSimpleNumber
              className="justify-center w-36"
              title="Total"
              subtitle="kg CO2e / m²"
              value={props.info.gwp_per_m2}
            />

            <DashboardSimpleNumber
              className="justify-center w-36"
              title="Total"
              subtitle="kg CO2e"
              value={props.info.gwp_nominal}
            />
          </div>
        </div>
        <div className="">
          <BarPlot
            traces={props.info.traces ?? []}
            xUnit={store.environmentalUnitSelected}
          />
        </div>
      </div>
    </div>
  );
});
