import React from "react";
import { IconSearch, IconSparkles, IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { useParams } from "react-router";
import { OperationType } from "api-client";
import { searchStore } from "store/IfcMapping/SearchStore";

const BulkActionBar = () => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { ifc_id } = useParams();

  const handleAutoMapp = async (mode: OperationType) => {
    if (dynamicMEStore.selectAllChecked) {
      await dynamicMEStore.runFileOperation(ifc_id, mode);
      await dynamicMEStore.fetchObjectsList(0, true, ifc_id);
      dynamicMEStore.setSelectAllChecked(false);
      dynamicMEStore.setSelectedRows([]);
    } else {
      await dynamicMEStore.runSelectedObjectOperations(ifc_id, mode);
    }
  };

  const openProductSearch = () => {
    dynamicMEStore.setCurrentRow(undefined);
    dynamicMEStore.setMatchForSelectedItems(true);
    searchStore.setOpenProductSearch(true);
  };

  React.useEffect(() => {
    searchStore.openProductSearch === false &&
      dynamicMEStore.setMatchForSelectedItems(false);
  }, [searchStore.openProductSearch]);

  const getSelectedObjectsTotalCount = () => {
    const total = dynamicMEStore.selectedRows
      .map((item) => item.object_count)
      .reduce((acc, value) => Number(acc) + Number(value), 0);
    return total ?? 0;
  };

  const getTitleValues = () => ({
    amount: dynamicMEStore.selectAllChecked
      ? t("mappingEditor.all")
      : dynamicMEStore.selectedRows.length,
    letter: dynamicMEStore.selectedRows.length === 1 ? "" : isEng ? "s" : "e",
  });

  const getGroupingTitleValues = () => ({
    amount: dynamicMEStore.selectAllChecked
      ? t("mappingEditor.all")
      : getSelectedObjectsTotalCount(),
    letter: getSelectedObjectsTotalCount() === 1 ? "" : isEng ? "s" : "e",
    groupCount: dynamicMEStore.selectedRows.length,
    letter2: dynamicMEStore.selectedRows.length === 1 ? "" : isEng ? "s" : "n",
  });

  if (dynamicMEStore.selectedRows.length === 0) return <></>;
  return (
    <div
      className={clsx(
        "h-10 flex items-center justify-center absolute bottom-14 bg-indigo-50 text-sm",
        "shadow-lg w-fit rounded-[20px] px-3 py-2 text-gray-800 text-base z-2"
      )}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t(
            dynamicMEStore.hasGrouping()
              ? "mappingEditor.actionBarTextGrouped"
              : "mappingEditor.actionBarText",
            dynamicMEStore.hasGrouping()
              ? getGroupingTitleValues()
              : getTitleValues()
          ),
        }}
      />
      <div className="text-indigo-700 flex ml-6">
        <IconSparkles
          size={24}
          className={clsx("mr-1 cursor-pointer hover:text-indigo-900", {
            "cursor-not-allowed pointer-events-none opacity-50":
              dynamicMEStore.showMappingLoading,
          })}
          onClick={() => handleAutoMapp("auto_map_match")}
        />
        <IconX
          size={24}
          className={clsx("cursor-pointer hover:text-indigo-900 mr-1", {
            "cursor-not-allowed pointer-events-none opacity-50":
              dynamicMEStore.showMappingLoading,
          })}
          onClick={() => handleAutoMapp("undo_match")}
        />
        <IconSearch
          className="cursor-pointer hover:text-indigo-900"
          onClick={openProductSearch}
        />
      </div>
    </div>
  );
};

export default observer(BulkActionBar);
