import { DashboardBuildingGeneralEmissions } from "api-client";
import BasePlot, { BasePlotProps } from "features/Dashboards/Base/BasePlot";
import DashboardSimpleNumber from "features/Dashboards/Base/DashboardSimpleNumber";
import { Datum } from "plotly.js";

export interface BuildingGeneralEmissionsProps {
  info: DashboardBuildingGeneralEmissions;
  plotXRange: number[];
}

const colors = [
  "rgba(99, 102, 241, 1)", // #6366f1
  "rgba(165, 180, 252, 1)", // #a5b4fc
  "rgba(6, 182, 212, 1)", // #06b6d4
  "rgba(103, 232, 249, 1)", // #67e8f9
];

const layout: Partial<Plotly.Layout> | object = {
  autosize: true,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  barmode: "relative",
  legend: {
    orientation: "h",
    y: -0.2,
  },
  margin: {
    l: 0,
    r: 0,
    b: 10,
    t: 0,
  },
  title: null,
  yaxis: {
    visible: false,
  },
  xaxis: {
    tickmode: "auto",
  },
};

const baseConfig: BasePlotProps = {
  data: [],
  layout,
  config: {
    displaylogo: false,
    showTips: true,
    responsive: true,
    displayModeBar: false,
  },
};

function EnergyPlot(props: {
  title: string;
  total: number | null | undefined;
  unit: string;
  plotConfig: BasePlotProps;
  plotXRange?: number[];
}) {
  const config = {
    ...baseConfig,
    ...props.plotConfig,
  };

  if (props.plotXRange && config.layout?.xaxis) {
    config.layout.xaxis.range = props.plotXRange;
  }

  return (
    <div className="rounded-lg bg-neutral-100 text-neutral-500 p-5 flex flex-col">
      <div>
        <h3 className="text-xs font-bold uppercase text-neutral-400">
          {props.title}
        </h3>
      </div>
      <div className="flex mb-3 h-28">
        <div className="flex w-1/3 text-center items-center">
          <DashboardSimpleNumber value={props.total} subtitle={props.unit} />
        </div>
        <div className="flex w-2/3">
          <BasePlot {...config} />
        </div>
      </div>
    </div>
  );
}

function GreyEmissionsPlot(props: {
  info: DashboardBuildingGeneralEmissions;
  plotXRange: number[];
}) {
  const input = props.info.gwp_traces ?? [];

  const plotConfig: BasePlotProps = {
    data: input.map((point, index) => {
      return {
        // transpose
        x: point["y"] as Datum[],
        y: point["x"] as Datum[],
        name: point.name,
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[index] },
      };
    }) as Partial<Plotly.Data>[],
  };

  return (
    <div className="h-full">
      <EnergyPlot
        title="Graue Emissionen"
        total={props.info.gwp_total}
        unit="kg CO2e / m²a"
        plotConfig={plotConfig}
        plotXRange={props.plotXRange}
      />
    </div>
  );
}

export function OperationalEnergyPlot(props: {
  info: DashboardBuildingGeneralEmissions;
  plotXRange: number[];
}) {
  const input = props.info.operational_energy_traces ?? [];

  const plotConfig: BasePlotProps = {
    data: input.map((point, index) => {
      return {
        // transpose
        x: point["y"] as Datum[],
        y: point["x"] as Datum[],
        name: point.name,
        orientation: "h",
        type: "bar",
        width: 0.2,
        marker: { color: colors[index] },
      };
    }) as Partial<Plotly.Data>[],
  };

  return (
    <div className="h-full">
      <EnergyPlot
        title="Emissionen im betrieb"
        total={props.info.operational_energy_total}
        unit="kg CO2e / m²a"
        plotConfig={plotConfig}
        plotXRange={props.plotXRange}
      />
    </div>
  );
}

export default function BuildingEnergyPanel(
  props: BuildingGeneralEmissionsProps
) {
  return (
    <div className="border mb-6 border-gray-300 bg-white rounded-lg overflow-hidden p-6">
      <div className="flex-col">
        <div className="mb-5">
          <h3 className="text-lg font-bold">Gesamt Emissionen (GWP)</h3>
        </div>
        <div className="grid grid-cols-7 gap-4 w-full">
          <div className="col-auto ">
            <DashboardSimpleNumber
              className="h-full justify-center"
              title="Gesamt"
              subtitle="kg CO2e / m²a"
              value={props.info.grand_total}
            />
          </div>
          <div className="col-span-3">
            <GreyEmissionsPlot
              info={props.info}
              plotXRange={props.plotXRange}
            />
          </div>

          <div className="col-span-3 h-full">
            <OperationalEnergyPlot
              info={props.info}
              plotXRange={props.plotXRange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
