import Heading from "components/Heading";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import ComplianceSettingsTile from "./ComplianceSettingsTile";
import BuildingPlots from "features/Dashboards/BuildingPlots";

export default observer(function LCPReport() {
  const { t } = useTranslation();

  return (
    <>
      <Heading size="title" className="mb-0">
        {t("buildingPassport.reports")}
      </Heading>
      <ComplianceSettingsTile />
      <BuildingPlots />
    </>
  );
});
