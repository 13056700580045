import React from "react";
import { InventoryCertificationOUT } from "api-client";
import { IconCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import SearchSortTable from "components/Table";
import { ColumnDef } from "@tanstack/react-table";
import useDatasetViewTableColumns, {
  EPDGroupColumns,
} from "./useDatasetViewTableColumns";
import { useComplianceInfo } from "hooks/useComplianceInfo";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/IfcMapping/SearchStore";

export interface EPDGroup {
  epds: EPDGroupColumns[];
  certifications?: InventoryCertificationOUT[];
  conformity?: string | null;
  selectedGroupID?: boolean;
}

function DatasetView({
  epdGroup,
  referenceUnit,
}: {
  epdGroup: EPDGroup | undefined;
  referenceUnit?: string;
}) {
  const { t } = useTranslation();
  const {
    getCertificationChipsGroup,
    getConformityChipsGroup,
    getCurrentConformity,
    getCurrentCertification,
  } = useComplianceInfo();

  function complianceContent(group: EPDGroup | undefined) {
    return (
      <>
        {epdGroup?.selectedGroupID &&
        searchStore.showComplianceAlertsInDetails ? (
          <div className="bg-green-50 text-green-800 p-2 flex items-center border-b border-gray-200">
            <IconCheck
              width={16}
              className="mr-2 p-1 h-6 w-6 bg-green-100 rounded-full text-green-800"
            />
            <span className="text-sm">
              {t("mapping.usedForCalculationAlert", {
                conformity: getCurrentConformity(),
                certification: getCurrentCertification()?.name,
              })}
            </span>
          </div>
        ) : null}
        <div className="pl-3 pt-3">
          <div className="flex mb-3">
            {group?.conformity ? (
              <div className="flex border-r border-gray-200 mr-1">
                {getConformityChipsGroup([group?.conformity])}
              </div>
            ) : null}
            {group?.certifications &&
              getCertificationChipsGroup(
                group.certifications.map((item) => item.name)
              )}
          </div>
        </div>
      </>
    );
  }

  function getColumns() {
    return useDatasetViewTableColumns(referenceUnit);
  }

  return (
    <div className="border border-gray-300 rounded-md mb-3 last-of-type:mb-0 overflow-hidden">
      <div>
        {complianceContent(epdGroup)}
        {epdGroup?.epds?.length ? (
          <SearchSortTable
            data={epdGroup.epds}
            getColumns={getColumns as () => ColumnDef<unknown, unknown>[]}
            headerRowStyle="bg-white whitespace-nowrap"
            headerColumnStyle="border-r last-of-type:border-r-0"
            headerDivStyle="!py-1"
            customColumnStyle="border-r last-of-type:border-r-0 whitespace-nowrap !py-1 !px-2"
            scrollDivClassName="bg-white scrollbar"
            enableColumnResizing
            enableTooltip
          />
        ) : null}
      </div>
    </div>
  );
}

export default observer(DatasetView);
