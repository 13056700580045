import clsx from "clsx";

export interface DashboardSimpleNumberProps {
  value?: number | string | null | undefined;
  title?: string;
  subtitle?: string;
  className?: string;
  valueClassName?: string;
  suffix?: string;
}

export default function DashboardSimpleNumber(
  props: DashboardSimpleNumberProps
) {
  return (
    <div
      className={clsx(
        "w-full flex flex-col text-center",
        props.className && props.className
      )}
    >
      {props.title && <h3 className="text-md">{props.title}</h3>}
      {props.value && (
        <h3 className="font-bold text-xl">
          {Number(props.value).toFixed(2)}
          {props.suffix && props.suffix}
        </h3>
      )}
      {props.subtitle && <h3 className="text-xs">{props.subtitle}</h3>}
    </div>
  );
}
