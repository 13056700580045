import Badge from "components/Badge";
import React from "react";
import LoadingIcon from "components/LoadingIcon";
import {
  IconCube,
  IconDeviceFloppy,
  IconSparkles,
  IconStack2,
} from "@tabler/icons-react";
import clsx from "clsx";
import { XIcon } from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import { ObjectProductMatch } from "api-client";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { searchStore } from "store/IfcMapping/SearchStore";

interface MappingChipProps {
  product: ObjectProductMatch;
  key?: number;
  unmatch?: () => Promise<void>;
  loading?: boolean;
  className?: string;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default observer(function MappingChip({
  product,
  key,
  unmatch,
  loading,
  className,
  setLoading,
}: Readonly<MappingChipProps>) {
  const showProductDetails = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dynamicMEStore.setOpenedProductDetailsPopup({
      open: true,
      id: product.id,
      name: product.name,
      type: product.product_type,
    });
    searchStore.setShowComplianceAlertsInDetails(true);
  };

  return (
    <div
      className="flex justify-start max-w-[80%] items-center"
      onClick={(e) => e.stopPropagation()}
    >
      <Badge
        key={key}
        type={product.compliance === "OK" ? "success" : "attention"}
        className="text-xs text-gray-700 !px-2"
      >
        <div
          onClick={showProductDetails}
          className="flex items-center overflow-hidden"
        >
          <div className="pr-1">
            {product.product_type === "PRODUCT" ? (
              <IconCube
                stroke={3}
                width={16}
                height={16}
                className="rounded-full"
              />
            ) : (
              <IconStack2
                stroke={3}
                width={19}
                height={19}
                className="rounded-full"
              />
            )}
          </div>
          <div className={clsx("truncate cursor-pointer", className)}>
            {product.name ?? "-"}
          </div>
        </div>
        {!loading && unmatch ? (
          <XIcon
            width={15}
            className={clsx("ml-2 cursor-pointer min-w-[15px] max-w-[15px]", {
              "text-yellow-500 hover:text-yellow-700":
                product.compliance !== "OK",
              "text-green-500 hover:text-green-700":
                product.compliance === "OK",
            })}
            onClick={async () => {
              setLoading?.(true);
              await unmatch?.();
              setLoading?.(false);
            }}
          />
        ) : (
          loading &&
          unmatch && (
            <LoadingIcon
              className={clsx("ml-2", {
                "!text-yellow-500": product.compliance !== "OK",
                "!text-green-500": product.compliance === "OK",
              })}
            />
          )
        )}
      </Badge>
      {product.match_type === "auto" ? (
        <div className="px-1">
          <IconSparkles className="text-gray-400 w-5" />
        </div>
      ) : null}
      {product.match_type === "pre" ? (
        <div className="px-1">
          <IconDeviceFloppy className="text-gray-400 w-5" />
        </div>
      ) : null}
    </div>
  );
});
