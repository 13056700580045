import { DashboardBuildingCPX } from "api-client";
import BasePlot, { BasePlotProps } from "features/Dashboards/Base/BasePlot";
import { PlotData } from "plotly.js";

const FONT_FAMILY =
  "Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif";

export interface BuildingCPXPanelProps {
  info: DashboardBuildingCPX;
}

function ArrowTopRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
      />
    </svg>
  );
}

const baseLayout: Partial<Plotly.Layout> | object = {
  autosize: true,
  margin: {
    l: 10,
    r: 10,
    b: 0,
    t: 0,
  },
};

const plotBaseConfig: BasePlotProps = {
  data: [],
  layout: baseLayout,
  frames: [],
  config: {
    modeBarButtonsToRemove: [
      "sendDataToCloud",
      "autoScale2d",
      "hoverClosestCartesian",
      "hoverCompareCartesian",
      "lasso2d",
      "select2d",
    ],
    displaylogo: false,
    showTips: true,
    responsive: true,
  },
};

function CpxGauge(props: {
  title: string;
  subtitle: string;
  value: number;
  color?: string;
}) {
  const data: Partial<PlotData> = {
    value: Math.round(props.value * 100),
    type: "indicator",
    mode: "gauge+number",
    gauge: {
      axis: { range: [0, 100], visible: false },
      bar: { color: props.color ?? "#06b6d4", thickness: 1 },
      borderwidth: 0,
      bordercolor: "white",
      bgcolor: "#f2f1f0",
    },
    number: {
      font: {
        family: FONT_FAMILY,
        size: 20,
      },
    },
  };
  const config = { ...plotBaseConfig, data: [data] };
  return (
    <div className="flex flex-col h-full">
      <div className="text-center items-center">
        <p className="text-base">{props.title}</p>
        <p className="text-xs">{props.subtitle}</p>
      </div>
      <div className="h-40">
        <BasePlot {...config} />
      </div>
    </div>
  );
}

export default function BuildingCPXPanel(props: BuildingCPXPanelProps) {
  return (
    <div className="border mb-6 border-gray-300 bg-white rounded-lg overflow-hidden p-6">
      <div className="flex justify-between">
        <h3 className="text-lg font-bold">
          Circularity Performance Index (CPX)
        </h3>
        <a
          className="border mb-6 border-gray-300 rounded-lg p-2 flex hover:bg-gray-100"
          href="https://concular.notion.site/Circularity-Performance-Index-CPX-8aa764b1b29344eabf96466f68c23ed8?pvs=4"
          target="_blank"
          rel="noopener noreferrer"
        >
          Berechnungslogik einsehen
          <div className="text-xs w-6 h-6 ml-2">{ArrowTopRight()}</div>
        </a>
      </div>

      <div className="w-full grid grid-cols-6 gap-4">
        <CpxGauge
          title="CPX Gesamt"
          color="#6366f1"
          subtitle="Total"
          value={props.info.cpx_total ?? 0}
        />
        <CpxGauge
          title="Materialherkunft"
          subtitle="Preuse"
          value={props.info.cpx_preuse_score ?? 0}
        />
        <CpxGauge
          title="Demontiertbarkeit"
          subtitle="Post-Use"
          value={props.info.cpx_deconstructability_score ?? 0}
        />
        <CpxGauge
          title="Wiederverwendungspotential"
          subtitle="Post-Use"
          value={props.info.cpx_reusability_score ?? 0}
        />
        <CpxGauge
          title="Trennbarkeit"
          subtitle="Post-Use"
          value={props.info.cpx_separability_score ?? 0}
        />
        <CpxGauge
          title="Rezyklierbarkeit"
          subtitle="Post-Use"
          value={props.info.cpx_recyclability_score ?? 0}
        />
      </div>
    </div>
  );
}
